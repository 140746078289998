<template>
  <div class="topup-dingdou">
    <el-card class="box-card">
      <div class="flex-center font3 dingdouheaderBox">
        <div class="dingdouheaderLeft">
          <span
            style="
              position: relative;
              bottom: 4px;
              font-size: 12px;
              color: rgba(51, 51, 51, 1);
              margin-right: 14px;
              margin-bottom: 5px;
            "
          >
            剩余叮豆(个)
          </span>

          <span>{{ $store.state.userInfo.t_amount }}</span>

          <helpIcon
            style="margin-left: 8px; position: relative; bottom: 2px"
            prompt="1元=10个叮豆；直播、录播、小班开课会消耗叮豆，建议保证叮豆数量充足以免影响讲师和学员的授课学习"
          ></helpIcon>
        </div>
        <div class="dingdouheaderRight">
          <p>当前网校 : {{ $store.state.userInfo.jg_title }}</p>
        </div>
      </div>
      <div class="dingdouContent">
        <div class="KfDialog" v-if="isKfDialog">
          <div class="Box">
            <img
              class="KfDialogX"
              @click="isKfDialog = false"
              src="../../assets/img/1.3.9.5/icon_gb@2x.png"
              alt=""
            />
            <div class="Imgbox">
              <img src="../../assets/img/1.3.9.5/img_kftc.png" alt="" />
            </div>
          </div>
        </div>
        <div class="dingdouContentLeft">
          <div class="commodity-main">
            <div
              :class="{
                'commodity active': item.active,
                commodity: !item.active,
              }"
              v-for="(item, index) in shoppings"
              @click="goods(item, index)"
              :key="index"
            >
              <img
                v-if="item.active"
                src="../../assets/img/1.3.9.5/img_dg@2x.png"
                alt=""
              />
              <p class="sum">
                <b>{{ item.sum }}叮豆</b>
              </p>
              <p style="margin-top: 3px">￥ {{ item.price }}</p>
              <div v-if="item.give > 0" class="give">
                <p class="triangle"></p>
                送{{ item.give }}叮豆
              </div>
            </div>
          </div>
          <div class="estimateBox">
            <h4>{{ selected.sum + selected.give }} 叮豆预估可用：</h4>
            <h5>
              说明：费用预估仅在指定的条件下进行估算，具体费用会根据实际使用情况结算
            </h5>
            <div class="estimateBoxNum">
              <div>
                <p>或录播/回放</p>
                <span>
                  {{
                    ((selected.sum + selected.give) / 8 / 0.2).toFixed(2) >
                    10000
                      ? (
                          (selected.sum + selected.give) /
                          8 /
                          0.2 /
                          10000
                        ).toFixed(1) + '万'
                      : ((selected.sum + selected.give) / 8 / 0.2).toFixed(0)
                  }}
                  <a>人学习</a>
                </span>
              </div>
              <div>
                <p>或普通直播</p>
                <span>
                  {{
                    ((selected.sum + selected.give) / 40 / 0.13).toFixed(0) >
                    10000
                      ? (
                          (selected.sum + selected.give) /
                          40 /
                          0.13 /
                          10000
                        ).toFixed(1) + '万'
                      : ((selected.sum + selected.give) / 40 / 0.13).toFixed(0)
                  }}
                  <a>分钟</a>
                </span>
              </div>
              <div>
                <p>或小班课</p>
                <span>
                  {{
                    ((selected.sum + selected.give) / 6 / 0.5).toFixed(2) >
                    10000
                      ? (
                          (selected.sum + selected.give) /
                          6 /
                          0.5 /
                          10000
                        ).toFixed(1) + '万'
                      : ((selected.sum + selected.give) / 6 / 0.5).toFixed(0)
                  }}
                  <a>分钟</a>
                </span>
              </div>
              <div>
                <p>或极速直播</p>
                <span>
                  {{
                    ((selected.sum + selected.give) / 3 / 1.5).toFixed(1) >
                    10000
                      ? (
                          (selected.sum + selected.give) /
                          3 /
                          1.5 /
                          10000
                        ).toFixed(1) + '万'
                      : ((selected.sum + selected.give) / 3 / 1.5).toFixed(0)
                  }}
                  <a>连麦分钟</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="dingdouContentY"></div>
        <div class="dingdouContentRight">
          <div class="text">
            <div>
              <p>应付：￥{{ selected.price }}</p>
            </div>
            <div>
              <p>到账：{{ selected.sum + selected.give }}叮豆</p>
              <h5>包含赠送的 {{ selected.give ? selected.give : '0' }}</h5>
            </div>
          </div>
          <div class="Ewm">
            <img
              class="rightImg"
              src="../../assets/img/1.3.9.5/img_syszf.png"
              alt=""
            />
            <img
              class="timeImg"
              @click="Buy()"
              v-if="timesEnd"
              src="../../assets/img/1.3.9.5/img_ewmsx.png"
              alt=""
            />
            <canvas width="158" height="158" id="QRCode"></canvas>
          </div>
          <div class="bottom">
            <p @click="goEvaluation()">在线估算器</p>
            <div></div>
            <p @click="isKfDialog = true">申请降低单价5%-30%</p>
          </div>
        </div>
      </div>
      <!-- <div class="content">
        <div class="flex-center">
          <el-button type="success" @click="Buy">立即支付</el-button>
          <div class="left">
            <div class="font1">已选：{{ selected.sum }}叮豆</div>
            <div class="font2">应付：￥{{ selected.price }}</div>
          </div>
        </div>
      </div> -->
    </el-card>
    <el-card class="box-card">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="model"
        :class="{ activing: activing1 == tab.id }"
        @click="modelselect(tab.id)"
      >
        <ul>
          <li>
            {{ tab.label }}
          </li>
        </ul>
      </div>
      <div style="clear: both"></div>
      <div class="bottom">
        <!-- aaa-->
        <template v-if="activing1 == 1">
          <table>
            <tr class="first_tr">
              <td>单价(元/分钟/人)</td>
              <td>计算公式</td>
              <td>举例</td>
            </tr>
            <tr>
              <td>{{ jigouAuth.live_price }}</td>
              <td>计算公式：直播时长（1天内）*峰值学习人数（1天内）*单价</td>
              <td>
                40分钟的直播课堂，直播间最高在线的学习人数为100人，单价{{
                  jigouAuth.live_price
                }}元； 直播费用 = 40 * 100 * {{ jigouAuth.live_price }} =
                {{ jigouAuth.live_price_count }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>1.直播间总人数不含讲师本人，讲师不参与计费</div>
                <div>2.系统结算时间为次日凌晨，可在网校账户中查看详情</div>
                <div>
                  3.按叮豆扣费，建议保证叮豆数量充足以免影响讲师和学员的授课学习
                </div>
              </td>
            </tr>
          </table>
        </template>
        <!-- bb -->
        <template v-if="activing1 == 2">
          <table>
            <tr>
              <th colspan="4" align="left">
                <p>计算公式：</p>
                <div>课堂费用 = 旁听费用 + 连麦费用</div>
                <div>连麦费用 = 同时最高连麦人数 * 直播时长 * 连麦单价</div>
                <div>
                  旁听费用 = (直播总在线人数 - 同时连麦最高人数) * 直播时长 *
                  旁听单价
                </div>
              </th>
            </tr>
            <tr class="first_tr">
              <td>同时连麦人数(人)</td>
              <td>连麦单价(元/分钟/人)</td>
              <td>旁听单价(元/分钟/人)</td>
              <td>举例</td>
            </tr>
            <tr>
              <td>1 v 6人</td>
              <td>{{ jigouAuth.speed_1v6 }}</td>
              <td rowspan="3">{{ jigouAuth.speed_live_price }}</td>
              <td>
                <div>
                  100人课堂，100人轮流上麦，最高同时连麦人数只算2人(上课老师算1人)，上课40分钟：
                </div>
                <div>
                  旁听费用 = (100-2) * 40 *{{ jigouAuth.speed_live_price }} =
                  {{ jigouAuth.speed_1v6_count1 }}
                </div>
                <div>
                  连麦费用 = 2 * 40 * {{ jigouAuth.speed_1v6 }} ={{
                    jigouAuth.speed_1v6_count2
                  }}
                </div>
                <div>
                  课堂费用 = {{ jigouAuth.speed_1v6_count1 }} +
                  {{ jigouAuth.speed_1v6_count2 }} =
                  {{ jigouAuth.speed_1v6_all }}
                </div>
              </td>
            </tr>
            <tr>
              <td>1 v 10人</td>
              <td>{{ jigouAuth.speed_1v10 }}</td>
              <td>
                <div>
                  100人课堂，50人轮流上麦，最高同时连麦人数达到10人(上课老师算1人)，上课40分钟：
                </div>
                <div>
                  旁听费用 = (100-10) * 40 *{{ jigouAuth.speed_live_price }} ={{
                    jigouAuth.speed_1v10_count1
                  }}
                </div>
                <div>
                  连麦费用 = 10 * 40 *{{ jigouAuth.speed_1v10 }} =
                  {{ jigouAuth.speed_1v10_count2 }}
                </div>
                <div>
                  课堂费用 = {{ jigouAuth.speed_1v10_count1 }} +
                  {{ jigouAuth.speed_1v10_count2 }} =
                  {{ jigouAuth.speed_1v10_all }}
                </div>
                <div></div>
              </td>
            </tr>
            <tr>
              <td>1 v 15人</td>
              <td>{{ jigouAuth.speed_1v15 }}</td>
              <td>
                <div>
                  100人课堂，50人轮流上麦，最高同时连麦人数达到15人(上课老师算1人)，上课40分钟：
                </div>
                <div>
                  旁听费用 = (100-15) * 40 * {{ jigouAuth.speed_live_price }}=
                  {{ jigouAuth.speed_1v15_count1 }}
                </div>
                <div>
                  连麦费用 = 15 * 40 *{{ jigouAuth.speed_1v15 }} =
                  {{ jigouAuth.speed_1v15_count2 }}
                </div>
                <div>
                  课堂费用 = {{ jigouAuth.speed_1v15_count1 }} +
                  {{ jigouAuth.speed_1v15_count2 }} ={{
                    jigouAuth.speed_1v15_all
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <p>说明：</p>
                <div>
                  1.连麦费用按同时最高连麦人数计算，100人连麦，但同时连麦只有2人(上课老师算1人)，也只按1~7人连麦单价计费
                </div>
                <div>2.同时连麦人数包含上课老师，旁听人数不包含老师；</div>
                <div>3.同时最高连麦人数不同，单价不同；</div>
                <div>4.系统结算时间为次日凌晨，可在网校账户中查看详情</div>
                <div>
                  5.扣费以叮豆扣费，1元=10颗叮豆，建议保证叮豆数量充足以免影响讲师和学员的授课学习
                </div>
              </td>
            </tr>
          </table>
        </template>
        <!-- cc -->
        <template v-if="activing1 == 3">
          <table>
            <tr class="first_tr">
              <td>单价(元/GB/人)</td>
              <td>计算公式</td>
              <td>举例</td>
            </tr>
            <tr>
              <td>{{ jigouAuth.video_price }}</td>
              <td>总费用=视频文件大小*学习人数*单价</td>
              <td>
                录播或回放视频文件为1GB，学习人数为100人，单价{{
                  jigouAuth.video_price
                }}元/GB/人； 总费用 = 1 * 100 *{{ jigouAuth.video_price }}=
                {{ jigouAuth.video_price_count }} 元
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>
                  1.学习人数为24小时内的人数，同一个人播放视频后，24小时重复学习不计费
                </div>
                <div>
                  2.系统结算时间为次日凌晨，可在网校账户中查看详情；叮豆不足时学员无法观看视频
                </div>
                <div>
                  3.扣费以叮豆扣费，建议保证叮豆数量充足以免影响讲师和学员的授课学习
                </div>
              </td>
            </tr>
          </table>
        </template>
        <!-- dd -->
        <template v-if="activing1 == 4">
          <table>
            <tr class="first_tr">
              <td>单价(元/分钟/人)</td>
              <td>计算公式</td>
              <td>举例</td>
            </tr>
            <tr>
              <td>0.05</td>
              <td>总费用=实际上课人数*实际上课时长*单价</td>
              <td>
                6人小班课堂，实际上课60分钟，单价0.05元/分钟/人
                总费用=6*60*0.05=18元
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>1.小班课最高支持1v6，创建课程时需要选择上课人数</div>
                <div>
                  2.课堂人数不含老师本人，创建时选择6人，实际上课3人，会按实际上课人数计算；当课堂仅有老师时，会按1人计费
                </div>
                <div>3.系统结算时间为次日凌晨，可在网校账户中查看详情</div>
                <div>
                  4.扣费以叮豆扣费，建议保证叮豆数量充足以免影响讲师和学员的授课学习
                </div>
              </td>
            </tr>
          </table>
        </template>

        <!-- eeee-->
        <template v-if="activing1 == 5">
          <table>
            <tr class="first_tr">
              <td>单价(元/条/人)</td>
            </tr>
            <tr>
              <td>{{ jigouAuth.collection_phone_price }}</td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>
                  1.短信计费单价为{{
                    jigouAuth.collection_phone_price
                  }}元/条，按叮豆方式结算（{{
                    jigouAuth.collection_phone_price * 10
                  }}叮豆/条）
                </div>
                <div>
                  2.学员绑定手机号时和使用短信登录网校需以短信形式发送验证码，将按实际条数产生短信费用
                </div>
              </td>
            </tr>
          </table>
        </template>

        <template v-if="activing1 == 6">
          <table>
            <tr class="first_tr">
              <td>视频加密 单价(叮豆/分钟)</td>
            </tr>
            <tr>
              <td>
                <span style="margin-right: 40px">
                  高清(720p)：{{ jigouAuth.transcode_price_720 }}
                </span>
                <span>蓝光(1080p)：{{ jigouAuth.transcode_price_1080 }}</span>
                <div style="margin-top: 5px">
                  扣费公式：视频时长（分钟）* 加密单价（叮豆/分钟）
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>
                  1.视频加密单价按高清和蓝光两种规格，单价分别为{{
                    jigouAuth.transcode_price_720 / 10
                  }}元/分钟和{{
                    jigouAuth.transcode_price_1080 / 10
                  }}元/分钟，按叮豆方式结算为{{
                    jigouAuth.transcode_price_720
                  }}叮豆/分钟和{{ jigouAuth.transcode_price_1080 }}叮豆/分钟
                </div>
                <div>
                  2.在使用版权保护系统对录播和直播回放选择保护方案时，将按实际加密视频时长产生费用消耗叮豆
                </div>
              </td>
            </tr>
          </table>

          <table style="margin-top: 30px">
            <tr class="first_tr">
              <td>视频转码 单价(叮豆/分钟)</td>
            </tr>
            <tr>
              <td>
                <span style="margin-right: 40px">
                  流畅(360p)：{{ jigouAuth.ts_price_360 }}
                </span>
                <span style="margin-right: 40px">
                  标清(480p)：{{ jigouAuth.ts_price_480 }}
                </span>
                <span style="margin-right: 40px">
                  高清(720p)：{{ jigouAuth.ts_price_720 }}
                </span>
                <span>蓝光(1080p)：{{ jigouAuth.ts_price_1080 }}</span>
                <div style="margin-top: 5px">
                  扣费公式：视频时长（分钟）* 转码单价（叮豆/分钟）
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>
                  目的：能在用户端更快更流畅的打开和播放；同时也节省网校流量成本
                </div>
                <div style="margin-top: 20px; margin-bottom: 20px">
                  规则：1080p为蓝光，720p为高清，480p为标清，360p为流畅；大于1080p，转码成1080p，比特率2500kbps；小于1080p大于720p，转码成720p，比特率1800kbps；小于720P大于480p：转码成480p，比特率900kbps；小于480P大于360p：转码成360p，比特率400kbps
                </div>
                <div>
                  说明：转码后会生成转码文件并保留源文件，会占用网校存储空间；转码规格大于720p及以上时，会消耗叮豆，不同规格单价不同；可在网校账户>>扣费记录查看明细。
                </div>
              </td>
            </tr>
          </table>
        </template>

        <template v-if="activing1 == 7">
          <table>
            <tr class="first_tr">
              <td style="width: 135px">单价(元/GB)</td>
              <td>计算公式</td>
              <td>举例</td>
            </tr>
            <tr>
              <td>
                <div>标准直播 {{ jigouAuth.open_class_live_price }}/GB</div>
                <div>快直播{{ jigouAuth.open_class_webrtc_price }}/GB</div>
                <!-- <div>智能直播 {{ jigouAuth.open_class_live_price }}/GB</div> -->
                <div>智能直播 {{ jigouAuth.open_class_webrtc_price }}/GB</div>
                <div>
                  连麦互动 {{ jigouAuth.live_call_price }}/分钟/人
                </div>
              </td>
              <td>
                <div>
                  总费用=消耗流量 * 单价；消耗流量=码率 / 8bit ×
                  所有人观看总时长(秒)
                </div>
                <div>连麦费用=连麦时长*单价</div>
              </td>
              <td>
                例如设置了600kbps码率，学员累计看了60分钟(3600秒)，即600/8*3600=270,000kb，换算后为270MB=0.27GB，则总费用为：0.27GB
                * 8 = 2.16 叮豆，即0.216元
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <p>说明：</p>
                <div>
                  1.8bit为1个字节；码率不同，直播清晰度不同，消耗的流量也会不同
                </div>
                <div>
                  2.流量计算是根据每位学员观看的时长计算，如直播30分钟，10位学员全程在线，总时长为300分钟
                </div>
                <div>3.系统结算时间为次日凌晨，可在网校账户中查看详情</div>
                <div>
                  4.按叮豆扣费，建议保证叮豆数量充足以免影响讲师和学员的授课学习
                </div>
                <div>
                  5.目前连麦互动的摄像头默认清晰度为480p，如有更高需求，请联系客户经理
                </div>
              </td>
            </tr>
          </table>
        </template>
      </div>
    </el-card>
    <!-- <el-dialog title="微信支付" :visible.sync="centerDialogVisible" width="30%" center @close="checkOrder">
      <p>充值金额：{{ buyPrice }}</p>
      <canvas width="244" height="244" id="QRCode"></canvas>
      <p>请在30分钟内完成支付</p>
    </el-dialog> -->
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
import QRCode from 'qrcode' //引入生成二维码插件

import bg from '../../mixin/background'

export default {
  mixins: [bg],
  components: {
    helpIcon,
  },
  data() {
    return {
      activing1: 1,
      tabs: [
        {
          id: 1,
          label: '直播课-普通模式',
        },
        {
          id: 2,
          label: '直播课-极速模式',
        },
        {
          id: 3,
          label: '录播/回放',
        },
        {
          id: 4,
          label: '小班直播',
        },
        {
          id: 5,
          label: '短信',
        },
        {
          id: 6,
          label: '视频加密/转码',
        },
        {
          id: 7,
          label: '公开课/智能直播',
        },
      ],
      jigouAuth: {},
      selected: [], //充值叮豆的数据-被选中的数据
      shoppings: [], //充值叮豆的数据
      QRCodeMsg: '', //生成二维码信息
      centerDialogVisible: false,
      buyPrice: '',
      checkOrderTime: '',
      isKfDialog: false,
      timeCzjc: null, //二维码有效期定时函数
      countDownTimes: 300, //二维码有效期
      timesEnd: true, //二维码有效期定时函数的判断值
    }
  },

  created() {
    if (this.$route.query.type) {
      this.activing1 = this.$route.query.type
    }
    const that = this
    that.getGoods()
    // console.log(this.$store.state.userInfo)
  },
  beforeDestroy() {
    clearInterval(this.timeCzjc)
    this.timeCzjc = null
  },

  methods: {
    goEvaluation() {
      const host = /-test/i.test(location.host)
        ? 'test-home.dingdingkaike.com'
        : /-release/i.test(location.host)
        ? 'release-home.dingdingkaike.com'
        : 'www.dingdingkaike.cn'
      // window.open(`https://${host}/evaluation`)
      this.$store.dispatch('open', `https://${host}/evaluation`)
    },
    modelselect(val) {
      this.activing1 = val
    },
    goods(val, key) {
      this.shoppings = this.shoppings.map((item, index) => {
        return Object.assign({}, item, {
          active: index == key,
        })
      })
      this.selected = this.shoppings[key]
      this.Buy()
      this.checkOrder()
      // this.accomplish()
    },
    Buy() {
      //立即充值
      this.countDownTimes = 300
      let that = this
      let isBuy = that.shoppings.filter(function (val) {
        return val.active == true
      })
      if (isBuy.length == 0) {
        that.$root.prompt('请选择商品')
        return
      } else {
        // that.centerDialogVisible = !that.centerDialogVisible
        // console.log(isBuy)
        that.buyPrice = isBuy[0].price
        let price = isBuy[0].price
        that.$http({
          name: 'rechargeInfo',
          url: '/wallet/recharge',
          data: {
            money: parseFloat(price),
          },
          callback({ data }) {
            that.getQRCode(data)
            //把获取二维码图片false掉
            that.timesEnd = false
          },
        })
      }
    },
    //生成分享二维码
    getQRCode(img) {
      this.QRCodeMsg = img //生成的二维码为URL地址
    },
    checkOrder() {
      //检查用户是否成功支付
      // console.log(2123)
      let that = this
      that.$http({
        name: 'checkOrderInfo',
        url: '/wallet/checkOrder',
        callback() {
          that.accomplish() //完成弹窗
          that.Buy() //完成后直接再模拟点击一次套餐 防止一直弹出完成弹框
          that.$store.dispatch('isEqualUserInfo')
        },
        error: (err) => {
          // that.accomplish()
          console.log(err)
        },
      })
    },
    accomplish() {
      //完成弹窗
      const h = this.$createElement
      // let isBuy = that.shoppings.filter(function (val) {
      //   return val.active == true
      // })
      // console.log(isBuy)

      this.$msgbox({
        title: '消息',
        message: h('p', null, [
          // h('span', null, isBuy[0].sum + '叮' + '颗叮豆已到网校账户 ')
          h('span', null, '充值成功'),
        ]),
        showCancelButton: false,
        confirmButtonText: '确定',
        beforeClose: (action, instance, done) => {
          done()
        },
      }).then(() => {})
    },

    getGoods() {
      const that = this
      that.$http({
        name: 'GetGoods',
        url: '/Wallet/rechargeMoney',
        callback: ({ data: { list, jigouAuth } }) => {
          this.shoppings = list
          //拿到数值出现二维码
          this.Buy()
          //2秒后检查是否付款
          this.timeCzjc = setInterval(() => {
            this.checkOrder()
            this.countDownTimes -= 2
            // console.log(this.countDownTimes)
            if (this.countDownTimes < 0) {
              this.timesEnd = true
            }
          }, 2000)
          this.selected = this.shoppings.find((item) => item.active == true)
          this.jigouAuth = jigouAuth
          // 普通直播
          this.jigouAuth.live_price_count = 40 * 100 * jigouAuth.live_price
          // 1~6
          this.jigouAuth.speed_1v6_count1 =
            (100 - 2) * 40 * jigouAuth.speed_live_price
          this.jigouAuth.speed_1v6_count2 = 2 * 40 * jigouAuth.speed_1v6
          this.jigouAuth.speed_1v6_all =
            this.jigouAuth.speed_1v6_count1 + this.jigouAuth.speed_1v6_count2
          //8~10
          this.jigouAuth.speed_1v10_count1 =
            (100 - 10) * 40 * jigouAuth.speed_live_price
          this.jigouAuth.speed_1v10_count2 = 10 * 40 * jigouAuth.speed_1v10
          this.jigouAuth.speed_1v10_all =
            this.jigouAuth.speed_1v10_count1 + this.jigouAuth.speed_1v10_count2
          //11~15
          this.jigouAuth.speed_1v15_count1 =
            (100 - 15) * 40 * jigouAuth.speed_live_price
          this.jigouAuth.speed_1v15_count2 = 15 * 40 * jigouAuth.speed_1v15
          this.jigouAuth.speed_1v15_all =
            this.jigouAuth.speed_1v15_count1 + this.jigouAuth.speed_1v15_count2
          //回放/录播
          this.jigouAuth.video_price_count =
            1 * 100 * this.jigouAuth.video_price
          if (this.$route.query.type) {
            this.$nextTick(() => {
              window.scroll({
                top: 999999,
                left: 0,
                behavior: 'smooth',
              })
            })
          }
          //视频加密
          this.jigouAuth.transcode_price_720 = jigouAuth.transcode_price_720
          this.jigouAuth.transcode_price_1080 = jigouAuth.transcode_price_1080
        },
      })
    },
  },
  watch: {
    // 通过监听获取数据
    QRCodeMsg(val) {
      // 获取页面的canvas
      var msg = document.getElementById('QRCode')
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, val, {
        width: 158,
        height: 158,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.KfDialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  top: 0;
  left: 0;

  .Box {
    position: relative;
    width: 600px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);

    .KfDialogX {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 14px;
      right: 14px;
      cursor: pointer;
    }
  }
}

.bottom {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  table,
  td,
  th {
    border: 1px solid #e8e8e8;
    padding: 9px 20px;
    font-size: 13px;
    line-height: 22px;

    div {
      font-size: 13px;
      line-height: 22px;
      font-weight: normal;
    }

    p {
      font-size: 13px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
    }
  }

  .first_tr {
    background-color: #f5f5f5;
  }

  th {
    background-color: #ededed;
  }
}

.dingdouheaderBox {
  min-width: 852px;
  height: 88px;
  background: #faffff;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;

  .dingdouheaderLeft {
    overflow: hidden;
  }

  .dingdouheaderRight {
    min-width: 140px;
    height: 19px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 19px;
  }
}

.font3 {
  font-size: 26px;
  font-weight: bold;
  color: rgba(255, 53, 53, 1);
}

.model {
  float: left;
  margin-bottom: 30px;
  cursor: pointer;

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 35px;
      float: left;
      width: 130px;
      height: 33px;
      border-radius: 17px;
      margin-right: 20px;
      text-align: center;
      background: rgba(245, 245, 245, 1);
    }
  }
}

.activing {
  ul {
    li {
      background: rgba(10, 162, 155, 1);
      color: #fff;
    }
  }
}

.give {
  color: #fff;
  font-size: 12px;
  background-color: #ff3535;
  position: absolute;
  border-radius: 0px 4px 0px 0px;
  width: 80px;
  height: 14px;
  bottom: 0;
  padding-top: 2px;
  top: -17px;
  left: 40px;
  line-height: 12px;

  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-bottom: 16px solid #ff3535;
    border-left: 12px solid transparent;
    border-right: 0px solid transparent;
    font-size: 0;
    line-height: 0;
    bottom: 0;
    left: -12px;
  }
}

.estimateBox {
  width: 460px;
  margin-top: 45px;
  padding-bottom: 17px;

  h4 {
    height: 15px;
    font-size: 15px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 15px;
  }

  h5 {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 12px;
    font-weight: normal;
    margin-top: 10px;
  }

  .estimateBoxNum {
    width: 460px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    position: relative;

    div {
      text-align: center;

      p {
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 12px;
      }

      span {
        height: 13px;
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 13px;
        margin-top: 10px;
        position: relative;
        top: 10px;

        a {
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #666666;
          line-height: 12px;
          font-weight: normal;
        }
      }
    }
  }
}

.el-button {
  width: 200px;
  color: #fff;
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:hover {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:focus {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.box-card {
  margin: 20px !important;
}

.txt {
  font-size: 12px;
  color: #9b9b9b;
  margin-left: 20px;
}

.dingdouContent {
  display: flex;
  justify-content: flex-start;

  .dingdouContentY {
    width: 1px;
    height: 400px;
    background: #eaeaea;
    border-radius: 1px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .dingdouContentRight {
    width: 387px;
    margin-top: 50px;

    .text {
      display: flex;
      justify-content: space-between;

      p {
        height: 16px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff3535;
        line-height: 16px;
      }

      h5 {
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 12px;
        font-weight: normal;
        margin-top: 10px;
      }
    }

    .Ewm {
      width: 158px;
      height: 158px;
      margin: 0 auto;
      margin-top: 57px;
      position: relative;

      .rightImg {
        position: absolute;
        right: -100px;
        top: -28px;
      }

      .timeImg {
        position: absolute;
        cursor: pointer;
      }
    }

    .bottom {
      margin: 0 auto;
      width: 240px;
      color: #0aa29b;
      text-align: center;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      div {
        width: 1px;
        height: 16px;
        background: #d8d8d8;
        border-radius: 1px;
      }

      p {
        height: 14px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #0aa29b;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }
}

.commodity {
  font-size: 14px;
  width: 128px;
  height: 60px;
  text-align: center;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  margin-top: 53px;
}

.commodity-main {
  display: flex;
  flex-wrap: wrap;
  width: 430px;
  justify-content: space-between;
  position: relative;
  img {
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 0%;
  }
}

.sum {
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #0aa29b;
  line-height: 14px;
  margin-top: 17px;
}

.price {
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 12px;
}

.active-price {
  color: red !important;
  padding-right: 10px;
}

.commodity.active {
  border-radius: 5px;
  border: 1px solid #1b9d97;
}

.commodity:hover {
  border-radius: 5px;
  border: 1px solid #1b9d97;
}

.title {
  margin-left: 20px;
}

.content {
  margin-left: 20px;
  font-size: 12px;
  margin-top: 20px;

  .el-button {
    height: 48px;
  }

  .left {
    margin-left: 30px;

    .font1 {
      font-size: 16px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .font2 {
      font-size: 14px;
      color: rgba(255, 53, 53, 1);
      margin-top: 8px;
    }
  }
}

.erweima-img {
  height: 138px;
  width: 138px;
  margin-top: 20px;
}

.txt-content {
  font-size: 12px;
  color: #999999;
  margin-top: 15px;
  line-height: 20px;
}

/deep/ .el-dialog__header {
  color: white;
  background: rgba(3, 178, 126, 1);
  border-radius: 4px 4px 0px 0px;
}

/deep/ .el-dialog__title {
  color: white;
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: white;
}

/deep/ .el-dialog__body {
  text-align: center;
}

/deep/ .el-message-box__title {
  font-size: 18px;

  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}
</style>
